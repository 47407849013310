.hideable {
  max-height: 500px;
  transition:
    max-height 0.85s ease-in,
    opacity 0.55s ease-in;
  opacity: 1;
}

.hide {
  max-height: 0;
  transition:
    max-height 0.55s ease-out,
    opacity 0.15s ease-out;
  overflow: hidden;
  opacity: 0;
}
