.clickable-text {
  opacity: 1;
  margin-top: 4px;
  cursor: pointer;
  color: #2096d1;
  transition:
    color 0.3s ease,
    text-decoration 0.3s ease;
  font-weight: bold;
}

.clickable-text:hover {
  color: #107bab;
  text-decoration: underline;
}

.small-text {
  color: #069ae4;
  font-weight: normal;
  font-size: 14px;
}

.card-header {
  margin-top: 2px;
  margin-bottom: 30px;
}
