.navbar {
  height: rem(700px);
  max-width: 200px;
  padding: var(--mantine-spacing-md);
  padding-right: 8px; /* revert to normal for mobile */
  display: flex;
  flex-direction: column;
  border-right: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));

  transition: width 0.25s ease;
}
  
.navbarMain {
  flex: 1;
}

.header {
  padding-bottom: var(--mantine-spacing-md);
  margin-bottom: calc(var(--mantine-spacing-md) * 1.5);
  border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.footer {
  padding-top: var(--mantine-spacing-md);
  margin-top: var(--mantine-spacing-md);
  border-top: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: var(--mantine-font-size-sm);
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-1));
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm);
  border-radius: var(--mantine-radius-sm);
  font-weight: 500;
  
  &:hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    cursor: pointer;
    .linkIcon {
      color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    }
  }

  &[data-active] {
    &,
    &:hover {
      background-color: var(--mantine-color-blue-light);
      color: var(--mantine-color-blue-light-color);

      .linkIcon {
        color: var(--mantine-color-blue-light-color);
      }
    }
  }
}

.linkIcon {
  color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-2));
  margin-right: var(--mantine-spacing-sm);
  width: rem(25px);
  height: rem(25px);
}

.user-menu {
  cursor: pointer;
  background: white;
  padding: 8px;
  border-radius: 8px; /* Adjust as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.09); /* Uniform drop shadow */
  border: 1px solid var(--salv-dark-1);
  margin-bottom: '44px';
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
}

.user-menu:hover {
    background-color: #f7f7f7; /* Slightly darker shade for hover */
}


.full_logo {
  width: 95px;
  margin: 4px;
}

.small_logo {
  width: 30px; /* Adjust width for smaller logo */
  margin: 2px;
  display: none; /* Hide smaller logo by default */
  margin-top: 12px;
}

.email {
  animation: fadeIn 0.3s ease forwards;
}

@media (max-width: 1400px) { /* Replace 800px with var(--sidenav-collapse-threshold) when supported */
  .navbar {
    width: 66px; /* Collapsed width */
    padding: var(--mantine-spacing-xs);
    position: relative;
    left: 5px;
  }

  .sideNavBarHeader {
    width: 100%;
  }

  .email {
    display: none;
    opacity: 0.0;
  }

  .full_logo {
    display: none; /* Hide full logo when screen is small */
  }

  .icon_selector {
    display: none;
  }

  .small_logo {
    display: inline; /* Show smaller logo */
  }

  .link {
    padding: var(--mantine-spacing-xs);
  }

  .link span {
    display: none; /* Hide labels */
    padding: 0px;
  }

  .linkIcon {
    margin-right: 0; /* Center icons */
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
