.transcript-chat .suggested-prompt:hover {
    background-color: var(--mantine-color-gray-0);
    cursor: pointer;
}

.transcript-chat .user-avatar {
    margin-top: auto;
    margin-bottom: 2px;
}

.transcript-chat .send-message-button:hover {
    background-color: var(--mantine-color-blue-6);
}

.chat-drawer.mantine-Drawer-root {
    --mb-z-index: 1111200 !important;
}

.chat-drawer.multi-candidate.mantine-Drawer-root {
    --mb-z-index: 199 !important;
}

/* Make all content in transcript-chat 14px */
.transcript-chat * {
  font-size: 14px !important;
}

/* Fix paragraph line height only */
.transcript-chat p {
  line-height: 1.5 !important;
}

/* Fix list markers to respect padding */
.transcript-chat ul,
.transcript-chat ol {
  list-style-position: inside !important;
  padding-left: 0 !important;
}

/* Optional: add a bit of indent for list items for better readability */
.transcript-chat li {
  padding-left: 1em !important;
  text-indent: -1em !important;
}

/* Fix for bold text appearing on new line after list numbers */
.transcript-chat li > strong,
.transcript-chat li p > strong {
  display: inline !important;
}

.transcript-chat li > p {
  display: inline !important;
  margin: 0 !important;
}

/* Make sure the list item contents stay together */
.transcript-chat li {
  white-space: normal !important;
  line-height: 1.5 !important;
}
