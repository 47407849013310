.description-text-area {
  max-height: 80vh; /* vh is the viewport height unit */
  overflow-y: auto; /* Adds a scrollbar if the content exceeds the max height */
}

.top-button {
  margin: 10px;
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.job-button {
  background-color: #007bff;
}

.download-button {
  background-color: hsl(198, 100%, 50%);
}

.top-row {
  display: flex;
  justify-content: space-between; /* Aligns children with space between them */
  width: 100%; /* Adjust width as needed */
  margin-bottom: 10px;
}

.center-buttons {
  top: 70px;
  display: flex;
  flex-grow: 1; /* Allows the wrapper to grow and fill space for centering */
  margin-right: auto;
  margin-left: auto;
  gap: 50px;
  align-items: center;
}

.selectable-row {
  margin-bottom: 20px;
  background: white;
  border-radius: 12px;
  padding: 5px;
  border: 2px solid #8080804f;

  transition:
    border-color 0.3s,
    background-color 0.3s;
}

.selectable-row:hover {
  cursor: pointer;
  background: rgba(160, 187, 249, 0.138);
}

.selectable-row.selected {
  border-color: #028ccf;
  background: rgba(160, 187, 249, 0.186);
}

.button-link {
  background: none;
  color: blue;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  display: inline;
  text-decoration: underline;
  outline: inherit;
}

.button-link:hover,
.button-link:focus {
  text-decoration: none;
}

.disabled-button {
  cursor: default !important;
  opacity: 0.5;
}

.default-download-btn {
  opacity: 1;
  transition: opacity 0.2s;
}

.default-download-btn:hover {
  opacity: 0.5;
  cursor: pointer;
}
