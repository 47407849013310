@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.06);
  }
  100% {
    transform: scale(0.99);
  }
}

.hangup-button {
  padding: 10px 18px;
  background: rgb(232, 61, 61);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.86;
  transition: opacity 0.3s ease;
}

.hangup-button:hover {
  opacity: 1;
}

.start-button {
  padding: 12px 20px;
  background: rgb(37, 147, 37);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.86;
  transition: opacity 0.3s ease;
}
  
.start-button:hover {
  opacity: 1;
}

.avatar-container {
  bottom: 40px;
  right: 40px;
}

.candidate-container {
  bottom: 40px;
  left: 40px;
}

@media (max-width: 600px) {
  .avatar-container {
    bottom: 16px;
    right: 16px;
  }
  
  .candidate-container {
    bottom: 16px;
    left: 16px;
  }
}
