.header-tab {
    color: black; /* Default text color */
}

.header-tab:hover {
    background-color: var(--salv-dark-0-0);
    color: black; /* Text color when active */
}

.header-tab[data-active] {
    background-color: var(--salv-dark-0); /* Background color when active */
    color: black; /* Text color when active */
}

div[class^="stytch-admin-portal-jss"] {
    padding: 0px;
    border: 0px;
}
