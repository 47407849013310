.background-image {
  background-image: url('../../../public/imgs/vkda_img.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.login-card {
  width: 600px;
  padding: 75px;
  padding-left: 100px;
  padding-right: 100px;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0px 8px 50px rgb(0 0 0 / 20%);
}
