.import-job-button {
    padding: 12px 20px;
    border-radius: 12px;
    border: 1px solid var(--salv-dark-1);
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.import-job-button:hover {
    background-color: var(--salv-dark-1);
    border: 1px solid var(--salv-dark-3);
}

.zero-margin-content * {
    margin: 0 !important;
}

.import-job-row {
    cursor: pointer;
}

.import-job-row:active {
    background-color: var(--salv-dark-1);
}
