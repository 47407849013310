.transcript-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 85vh;
  padding: 0 10px;
  padding-top: 10px;
}

/* Special styling for test calls */
.mantine-Modal-body .transcript-container {
  margin-top: -50px;
  margin-bottom: 10px;
}

.mantine-Drawer-body .transcript-container {
  margin-top: 0px;
  padding: 10px;
  height: calc(100vh - 50px);
}

.transcript-call-recording {
  /* bottom: 1rem;
  right: 1rem; */
  cursor: pointer;
  /* position: absolute; */
  color: #3094ff;
  /* z-index: 10; */
  text-decoration: none;
}

.transcript-call-recording:hover {
  color: #0056b3;
}

.transcript-column,
.qa-column {
  width: 48%;
  height: 100%;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.transcript-column {
  width: 100%;
}

.column-title {
  margin-bottom: 10px;
  margin-top: 10px; /* Add some top margin to the titles */
}

.grade {
  color: var(--mantine-color-blue-5);
  font-weight: 600;
  margin-right: 12px;
  font-size: 18px;
}

.transcript-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  position: relative;
  overflow: hidden;
}

.scroll-fade-top,
.scroll-fade-bottom {
  position: absolute;
  left: 0;
  right: 0;
  height: 25px;
  /* Height of the fading effect */
  pointer-events: none;
  z-index: 2;
  width: calc(100% - 10px);

  /* Ensure it's above the content */
}

.scroll-fade-top {
  background: linear-gradient(to bottom, #ffffff, rgb(0, 0, 0, 0));
}

.scroll-fade-bottom {
  bottom: 0;
  background: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
}

.scrollable-content {
  /* Adjust based on your requirement */
  overflow-y: auto;
  overflow-x: hidden;
  word-wrap: break-word;
  text-align: left;
  position: relative;
  white-space: pre-wrap;
}

.qa-item {
  margin-bottom: 10px;
  border: 1px solid var(--salv-dark-1);
  border-radius: 8px;
  padding: 8px;
}

.answer {
  /* margin-left: 4px; */
  padding: 8px;
  font-size: 14px;
  padding-bottom: 4px;
  /* Indentation for answers */
}

.inside {
  padding-top: 20px;
  padding-bottom: 20px;
}

.question {
  font-weight: 700;
  margin-top: 2px; /* lines it up with the number */
}

.start-chat-button span {
  height: 30px;
}

/* not modal or drawer => no login link transcript view */
.phone-transcript-column {
  height: calc(100vh - 160px);
  padding-top: 2px;
  /* padding-left: 12px; */
  padding-right: 20px;
}

/* modal => test calls transcript view */
.mantine-Modal-body .phone-transcript-column {
  height: calc(100% - 30px);
  margin-bottom: 10px;
}

.video-transcript-column {
  height: calc(100vh - 190px);
}

/* drawer => campaign details page transcript view */
.mantine-Drawer-body .phone-transcript-column {
  height: calc(100vh - 110px);
}

.mantine-Drawer-body .video-transcript-column {
  height: calc(100vh - 110px);
}

.clickable:hover {
  cursor: pointer;
  opacity: 0.7;
}

.clickable-qa-item:hover {
  cursor: pointer;
  background-color: var(--mantine-color-gray-0);
}

.calls-container {
  height: calc(100vh - 90px);
  padding-top: 2px;
  padding-right: 20px;
}

.keyboard-shortcut-container {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 10px;
  border: 1px solid var(--salv-dark-1);
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: white;
  z-index: 99999;
}

.keyboard-shortcut-content {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.comments-section {
  margin-top: 22px;
  padding: 15px;
  padding-left: 0px;
  margin-bottom: 8px;
}

.comment {
  padding: 10px;
  margin: 10px 0;
  background: var(--salv-dark-0);
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
}

.comment p {
  margin: 0 0 0 0;
}

.comment small {
  color: #666;
}

.new-comment {
  margin-top: 15px;
}

.new-comment textarea {
  width: 100%;
  min-height: 10px;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 12px;
}

/* .new-comment button {
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
} */

.new-comment button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.comment {
  position: relative;
}

.comment .mantine-ActionIcon-root {
  opacity: 0;
  transition: opacity 0.2s;
}

.comment:hover .mantine-ActionIcon-root {
  opacity: 1;
}

/* 
new classes for pinned video
*/
.scrollable-container {
  height: 100%;
  position: relative;
  margin-bottom: 20px;
}

.video-container-sticky {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  padding-bottom: 10px;
}

.transcript-box {
  height: calc(100% - 200px);
  padding-top: 10px;
  overflow-y: auto;
  padding-bottom: 10px;
  scroll-padding-top: 20%;
  width: 100%;
}

.overflow-y-auto {
  overflow-y: auto;
  scroll-padding-top: 60px;
}

.transcript-message-active {
  background-color: var(--mantine-color-blue-1);
  outline: 1px solid var(--mantine-color-blue-1);
  outline-offset: 4px;
  border-radius: 8px;
  transition: all 0.2s ease;
  padding: 8px 12px;
  width: 96% !important;
  margin: 3px auto 0;
  box-sizing: border-box;
  scroll-margin: 5px;
  scroll-padding: 5px;
}

.divider {
  border: none;
  height: 1px;
  background-color: #e0e0e0;
}

.dimmed {
  color: var(--mantine-color-dimmed);
}

.comments-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100px;
}

.loading-text {
  margin-top: 10px;
  font-size: 13px;
  color: var(--mantine-color-gray-6);
}
