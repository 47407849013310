.requirementTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  min-width: 700px;
}

.requirementTable th,
.requirementTable td {
  padding: var(--mantine-spacing-xs);
  vertical-align: top;
  overflow: hidden;
  white-space: normal;
}

.gripTd:hover {
  color: var(--mantine-color-blue-2);
  cursor: grab;
}

.editableField {
  width: 100%;
  min-height: 36px;
  word-break: break-word;
}

.tableTextArea textarea {
  border: none;
  background: none;
}

.editableText {
  border: 1px solid #cac9c900;
  border-radius: 6px;
  padding: 1px;
}

.editableText:hover {
  border: 1px solid var(--salv-dark-2);
  cursor: text;
}

.dragIcon {
  position: relative;
  right: 8px;
}

.section-label {
  margin-bottom: 4px;
  margin-top: 2px;
}

.subsection-label {
  margin-bottom: 2px;
  margin-top: 2px;
}

.subsection-description {
  margin-top: 1px;
  margin-bottom: 1px;
  font-size: 12px;
  opacity: .6;
} 