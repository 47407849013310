/* Set the height to full screen */
.campaign-details-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent any overflow at the container level */
}

/* Table container takes up remaining space with proper scroll */
.common-table-wrapper {
  flex: 1;
  overflow-y: auto !important; /* Scroll only when needed */
  overflow-x: auto; /* Handle horizontal scroll if needed */
  min-height: 200px; /* Minimum height for the table */
}

/* Filter bar with stats row positioning */
.campaign-page-filter-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.filter-section {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
}

.stats-section {
  flex-shrink: 0;
  margin-left: 16px;
}

.refreshIconContainer {
  cursor: pointer;
  color: var(--salv-dark-6);
}

.refreshIconContainer:hover {
  cursor: pointer;
  color: var(--mantine-color-blue-8);
}
