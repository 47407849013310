.domains-list {
    display: flex;
    overflow-x: scroll;
    gap: 40px;
    border: 1px solid #d0cdcd7a;
    border-radius: 6px;
    padding: 16px;
}

.domain-card {
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    border: 1px solid var(--salv-dark-2);
    padding: 30px;
    padding-top: 15px;
    border-radius: 12px;
    min-width: 400px;
}

.emailSettingsContainer {
    margin-top: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    p {
        font-size: 14px;
    }
}

.scroll-container {
    max-height: 120px; /* Define the height of the scrollable area */
    overflow-y: scroll;
    position: relative;
}

.integration-link-container {
    border: 1px solid #80808047;
    border-radius: 6px;
    text-align: center;
    padding: 24px 40px;
    padding-bottom: 14px;
}

.integration-link-img {
    width: 50px;
    height: 48px;
    position: 'relative';
}

.integration-link-container:hover {
    background: var(--salv-dark-0);
    cursor: pointer;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
    background:  #fff9f9af;
}


.full-screen-message {
    height: 80%;
    background: white;
    margin: 25px 10%;
    border-radius: 10px;
    text-align: center;
    align-content: center;
}
