.file-preview h1 {
  font-size: 0.9rem !important; /* Set h1 font size */
}

.file-preview h2 {
  font-size: 0.9rem !important; /* Set h2 font size */
}

.file-preview h3 {
  font-size: 0.7rem !important; /* Set h2 font size */
}
