div {
    p.mantine-Textarea-description {
        margin-top: 4px;
        padding-top: 2px;
        padding-left: 10px;
        padding-right: 4px;
    }
}

.section-label {
    margin-bottom: 4px;
    margin-top: 2px;
}

.subsection-label {
    margin-bottom: 2px;
    margin-top: 2px;
}

.subsection-description {
    margin-top: 1px;
    margin-bottom: 1px;
    font-size: 12px;
    opacity: .6;
}
