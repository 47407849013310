.custom-audio {
    width: 75%; /* Adjust the width as needed */
    background-color: #ffffff; /* Background color */
    border-radius: 50px; /* Rounded corners */
    padding: 5px; /* Padding for a better look */
    height: 44px;
    max-width: 440px;
}
/* 
/* Hide default controls and create custom ones if needed */
.custom-audio::-webkit-media-controls-panel {
    background-color: white; /* Custom background for the controls */
}
