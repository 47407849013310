.clickable-icon {
    opacity: .3;
}

.clickable-icon:hover {
    opacity: 1;
}

.thumb-icon {
    height: 20px;
}

.thumb-up {
    color: var(--mantine-color-green-4);
}

.thumb-down {
    color: var(--mantine-color-red-4);
}

.comment-icon {
    color: var(--mantine-color-gray-5);
    height: 21px;
    transition: color 0.2s ease;
    stroke-width: 1.8;
}

.candidate-feedback-container {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    transition: all 0.2s ease;
    margin-left: -10px;
}

.candidate-feedback-container.popover-open {
    z-index: 9000; 
}

.comments-count-container{
    position: relative;
}

.comments-count{
    position: relative;
    color: var(--mantine-color-gray-5);
    font-weight: 700;
    font-size: 11px;
    top: 10px;
    right: 2px;
}

.popover-open .comment-icon {
    color: var(--mantine-color-dark-6); 
    visibility: visible;
}

.no-comments .comment-action-button {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.candidate-row:hover .no-comments .comment-action-button {
    opacity: 0.7;
    visibility: visible;
}

.no-comments .comment-action-button:hover {
    opacity: 1;
}

.has-comments .comment-action-button {
    opacity: 1;
    visibility: visible;
}

.has-comments .comment-icon {
    opacity: 0.7; 
}

.has-comments .comment-action-button:hover .comment-icon {
    opacity: 1; 
}

.comments-popover-dropdown {
    width: 350px !important;
    max-width: 350px !important;
    z-index: 9500 !important;
    padding: 0 !important;
    margin: 0 !important;
}

.comments-popover-content {
    padding: 0 !important;
    margin: 0 !important;
}

.comments-section.in-popover {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
}

.comments-popover-dropdown .comments-list {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 8px;
    padding: 0 8px;
}

.comments-popover-dropdown .no-comments-message {
    color: var(--mantine-color-gray-6);
    font-style: italic;
    padding: 8px 0;
    text-align: center;
    font-size: 14px;
}

.comments-popover-dropdown .comment {
    background-color: var(--mantine-color-gray-0);
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 8px;
}

.comments-popover-dropdown .comment-author {
    font-weight: 600;
    font-size: 13px;
}

.comments-popover-dropdown .comment-date {
    display: block;
    color: var(--mantine-color-gray-6);
    font-size: 11px;
}

.comments-popover-dropdown .comment-text {
    padding: 6px;
    font-size: 13px;
    margin: 4px 0;
    word-break: break-word;
}

.comments-popover-dropdown .new-comment {
    margin: 0;
    padding: 6px 8px;
}

.comments-popover-dropdown .comment-buttons {
    display: flex;
    width: 100%;
    gap: 8px;
    margin-top: 6px;
    justify-content: flex-end;
}

.comments-popover-dropdown .discard-button {
    padding: 0 15px;
}

.comments-popover-dropdown .save-button {
    padding: 0 15px;
}

.comment-action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.mantine-Popover-root {
    z-index: 9500 !important;
}

.comment-button-container {
    position: relative;
    display: inline-flex;
    margin-bottom: 2px;
}

.comments-count-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    font-size: 10px;
    min-width: 16px;
    height: 16px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    z-index: 1050; 
    transition: opacity 0.3s ease;
}

.has-comments .comments-count-badge {
    display: flex !important; 
    opacity: 1 !important;
    visibility: visible !important;
}

.no-comments .comments-count-badge {
    display: none;
}

.candidate-row:hover .no-comments .comments-count-badge {
    display: flex;
}
