.th {
  padding: 0;
}

.control {
  position: relative;
}

.control p,
.control .icon {
  font-weight: 500;
  color: #2d2f33;
  /* transition: color 0.2s ease-in; */
}

.control:hover p,
.control:hover .icon {
  color: black;
  font-weight: 600; /* Or any other visual change */
}

.sorted p,
.sorted .icon {
  color: black;
  font-weight: 600;
}

.hidden {
  visibility: hidden;
  /* position: absolute; MESSES IT UP*/
  top: 0;
  left: 0;
  height: 0;
}

.hidden p,
.hidden .icon {
  color: black;
  font-weight: 600;
}
