.campaign-detail-stats-row {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
}

.stat-item {
    text-align: center;
    display: flex;
    gap: .5rem;
    align-items: center;
}

.stat-item .mantine-Text {
    display: block;
}

.stat-item-text {
    margin-top: 0px;
}


.progress-container {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.stat-row-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 5px;
}

.aligned-text-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
  }
  
  .aligned-text-left {
    text-align: left;
  }
  
  .aligned-text-right {
    text-align: right;
  }

  .help-icon:hover {
    cursor: pointer;
  }