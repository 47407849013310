.page-container-common {
    padding: 20px;
    width: 100%;
    background: white;
    margin: 12px;
    border-radius: 8px;
    overflow: scroll;
    padding-bottom: 0px;
}

.scroll-fade-top,
.scroll-fade-bottom {
  position: absolute;
  left: 0;
  right: 0;
  height: 25px;
  /* Height of the fading effect */
  pointer-events: none;
  z-index: 2;
  width: calc(100% - 10px);

  /* Ensure it's above the content */
}
