/* App.css or your main CSS file */
.markdown-content {
  white-space: pre-wrap;
  line-height: 0.1;
  margin: 0;
  padding: 0;
  word-break: break-word;
}

.markdown-content ul {
  /* list-style-type: none; */
  margin: 0;
  line-height: 1.4;
}

.markdown-content li {
  line-height: 1.3;
}

.markdown-content ul,
.markdown-content ol {
  margin: 0;
  line-height: 0;
}

.markdown-content li > p {
  margin: 0;
  line-height: 1.4;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  margin: 0;
  padding: 5px 0; /* Adjust as needed */
  line-height: 1.5;
}

.markdown-content p {
  margin: 0;
  padding: 5px 0; /* Adjust as needed */
  line-height: 1.2;
}
