.connect-ats-button {
    border: 1px solid var(--salv-dark-3);
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    width: 300px;

    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    /* background-color: var(--salv-dark-0); */
    
    /* Add transition for smooth animation */
    transition: all 0.2s ease-in-out;
}

.connect-ats-button:hover {
    background-color: var(--salv-dark-1);
    /* Add slight scale effect on hover */
    /* transform: scale(1.005); */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}
