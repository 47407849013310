html, body, #root {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: var(--salv-dark-0);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', 'system-ui', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  min-height: 100vh;

  overflow: hidden;
}

#root {
  min-height: 100%;
  height: 100%;
}


:root {
  /* Define color variables */
  --salv-dark-9: #222324;
  --salv-dark-8: #2c2c2e;
  --salv-dark-7: #393a3c;
  --salv-dark-6: #4a4e56;
  --salv-dark-5: #646a71;
  --salv-dark-4: #7b828b;
  --salv-dark-3: #a3acbc;
  --salv-dark-2: #c2cdd7;
  --salv-dark-1: #d8e1e9;
  --salv-dark-0: #F4F7FA;
  --salv-dark-0-0: #F4F7FAfd;

  --salv-warning-yellow: #ffd96a;

  --table-layout: fixed;

  --sidenav-collapse-threshold: 800px;

  --view-only-bg: #f1f3f599;

  --salv-phone-color: var(--mantine-color-blue-4);
  --salv-video-color: var(--mantine-color-violet-5);
  --salv-folder-color: var(--salv-dark-4);
}

.view-only {
  cursor: not-allowed !important; 
  /* cursor effects do not currently take effect because pointer events none. need to put in wrapper if want cursor effects */
  background-color: var(--view-only-bg) !important;
  pointer-events: none !important;
  /*
  special class, based on default disabled, but excludes lowering opacity
  */
}

/* For Webkit-based browsers (Chrome, Safari, newer Edge) */
textarea.view-only::-webkit-input-placeholder {
  color: transparent !important;
  opacity: 0 !important;
}

/* For Firefox */
textarea.view-only::-moz-placeholder {
  color: transparent !important;
  opacity: 0 !important;
}

/* For Internet Explorer 10-11 */
textarea.view-only:-ms-input-placeholder {
  color: transparent !important;
  opacity: 0 !important;
}

/* For modern browsers */
textarea.view-only::placeholder {
  color: transparent !important;
  opacity: 0 !important;
}

.view-only-parent {
  /* useful for components with children besides the input we want to disable but not color*/
  cursor: not-allowed !important; 
  pointer-events: none !important;
}

.ka-wrapper {
  height: 100%;
  display: contents;
}

.ka-content  {
  height: 100%;
  display: contents;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.common-table-wrapper {
  height: calc(100% - 50px); /* This size works for some components but may need to be overridden by most */
  overflow-y: scroll;
  background: white;
  border-radius: 8px;
}

/* Sticky headers */
th {
  position: sticky;
  top: 0;
  background-color: var(--salv-dark-0); /* retell background */
  height: 54px;
  z-index: 1;
  font-weight: 500;
  color: #2d2f33;
}

td {
  height: 48px;
}

h1,h2,h3,h4,h5,h6 {
 font-weight: 500;
 color: var(--salv-dark-6); /* retell / cal gray for text font */
}

tr {
  transition: background-color 0.15s ease;
}

tr:hover {
  background-color: #F4F7FA;
}

.status-badge {
  background-color: #F4F7FA;
  font-weight: 500;
  color: #4a4e56;
}

/* needed to beat mantine for some reason*/
div.status-badge {
  background-color: #F4F7FA;
  font-weight: 500;
  color: #4a4e56;
}

td, th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

td.no-ellipsis, .no-ellipses {
  text-overflow: clip;
}

button.mantine-CloseButton-root{
  z-index: 100000;
}
