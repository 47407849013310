.editorPageContainer {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 4.0fr;
  grid-template-rows: 1fr;
  gap: var(--mantine-spacing-lg);
  height: 100%;
  max-height: 100%;
}

.inner-grid {
  height: 100%;
  max-height: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: var(--mantine-spacing-xl);
}

.column-padding {
  padding: var(--mantine-spacing-md) 0px;
  height: 100%;
  overflow: scroll;
}

div.mantine-Popover-dropdown {
  min-width: 120px;
}

.stepContainer {
  height: max(calc(100% - 20px), 200px);
  display: flex;
  flex-direction: column;
}

.tableTextArea {
  textarea {
    border: none;
    background: none;
  }
  textarea:active{
    outline: none;
    border: none;
  }
  textarea:focus {
    outline: none;
    box-shadow: none;
  }
}

.titleInputArea {
  flex: 1;
  
  font-weight: 600;
  input {
    outline: none;
    border: none;
    padding: 0px;
  }
  textarea {
    font-size: 16px;
    border: none;
    background: none;
    padding: 0px;

    height: 24px; /* these will expand with the text, we just need to overwrite the default styling */
    min-height: 24px;
    padding-left: 4px;
  }

  textarea:active{
    outline: none;
    border: none;
  }
  textarea:focus {
    outline: none;
    box-shadow: none;
  }
}

.stepSectionContainer {
  height: 85%; /* This is to leave space for the bottom buttons on big screens */
  overflow: scroll;
  min-height: 300px;
  flex-grow: 1;
}

.stepButtonsContainer {
  flex-shrink: 0;
  margin-top: 20px;
  /* margin-top: 6px; */
}

.fullHeightTextarea {
  height: 90%;
  /* min-height: max(calc(100% - 150px), 150px); */
  padding-bottom: 24px;
  .mantine-Textarea-wrapper,
  .mantine-Textarea-input {
    height: 100%;
  }
}

.halfHeightTextarea {
  height: 50%;
  min-height: max(calc(100% - 400px), 200px);
  padding-bottom: 24px;
  .mantine-Textarea-wrapper,
  .mantine-Textarea-input {
    height: 100%;
  }
}

.fullHeightTextarea .mantine-Textarea-wrapper,
.fullHeightTextarea .mantine-Textarea-input {
    height: 100%;
}

.halfHeightTextarea .mantine-Textarea-wrapper,
.halfHeightTextarea .mantine-Textarea-input {
    height: 100%;
}

.requirementTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  min-width: 700px;
}

.requirementTable th,
.requirementTable td {
  padding: var(--mantine-spacing-xs);
  vertical-align: top;
  overflow: hidden;
  white-space: normal;
}

.callout {
  background: var(--mantine-color-yellow-0);
  color: gray;
  font-size: 12px;
  padding: 8px;
  border-left: 3px solid var(--mantine-color-yellow-3);
  border-radius: '8px';
}

.callout-md {
  font-size: 14px;
  color: gray;
  padding: 12px;
  padding-left: 16px;
}

.callout-big {
  font-size: 16px;
  color: black;
  padding: 14px;
  padding-left: 16px;
}

.requirementTable th:first-child,
.requirementTable td:first-child {
  width: 40px;
}

.requirementTable th:nth-child(3),
.requirementTable td:nth-child(3) {
  width: 40%;
}

.requirementTable th:nth-child(4),
.requirementTable td:nth-child(4) {
  width: 150px;
}

.requirementTable th:last-child,
.requirementTable td:last-child {
  width: 100px;
}

.requirementTable .editableField {
  width: 100%;
  min-height: 36px;
  word-break: break-word;

  /* Account for offset by other classes */
  position: relative;
  top: -8px;
}

.requirementTable .editableImportance {
  width: 100%;
}

.gripTd:hover {
  color: var(--mantine-color-blue-2);
  cursor: grab;
}

.gripTd:active {
  color: var(--mantine-color-blue-2);
}

.requirementTable tr:hover {
  background-color: transparent;
}

.requirementTable .importanceBadge {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.campaign-action-button {
  &:disabled,
  &[data-disabled] {
    /* border-color: light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4)); */
    background: rgba(219, 219, 219, 0.55);
  }
}

.paddingBottom {
  padding-bottom: 18px;
}

.dragIcon {
  position: relative;
  right: 8px;
}

.editableText {
  border: 1px solid #cac9c900;
  border-radius: 6px;
  padding: 1px;
}

.editableText:hover {
  border: 1px solid var(--salv-dark-2);
  cursor: text;
}

.editableText:active {
  border: 1px solid var(--salv-dark-2);
  cursor: text;
}

.editingBorder,
.editingBorder:hover,
.editingBorder:active {
  border: 1px solid var(--salv-dark-3);
}

.editingTitleActive,
.editingTitleActive:hover,
.editingTitleActive:active {
  border: 1px solid var(--salv-dark-2);
}

.wait-for-response {
  opacity: 0.6;
  margin: 4px 0;
}

span.mantine-NavLink-label {
  /* Your styles here */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div.mantine-NumberInput-section {
  padding-right: 4px;
}

.permanent-interview-link-nav-text {
  color: var(--mantine-color-blue-6);
  font-weight: 600;
}

.permanent-interview-link-nav-text:hover {
  text-decoration: underline;
  cursor: pointer;
}
