.header-tab {
    color: black; /* Default text color */
}

.header-tab:hover {
    background-color: var(--salv-dark-0-0);
    color: black; /* Text color when active */
}

.header-tab[data-active] {
    background-color: var(--salv-dark-0); /* Background color when active */
    color: black; /* Text color when active */
}

.nav-link-text:hover {
    cursor: pointer;
    text-decoration: underline;
}

/* div.mantine-Progress-section{
    background: linear-gradient(90deg, var(--salv-dark-2), var(--salv-dark-3));
} */

div.mantine-Progress-root {
    background: var(--salv-dark-0);
}

.custom-progress-style {
    /* Your conditional styles */
    border-start-start-radius: 0 !important;
    /* background: linear-gradient(90deg, var(--salv-dark-2), var(--salv-dark-3)); */
}
